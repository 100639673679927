import 'tailwindcss/dist/base.css';
import 'styles/globalStyles.css';
import React, { Suspense } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import TagManager from 'react-gtm-module';

const HomePage = React.lazy(() => import('components/old/home/HomePage'));
const PersonalizedCoaching = React.lazy(() =>
  import('components/old/pc/PersonalizedCoaching')
);
const MentorsPage = React.lazy(() =>
  import('components/old/coaches/CoachesPage')
);
const Super30Page = React.lazy(() =>
  import('components/old/super30/Super30Page')
);
const NewSuper30Page = React.lazy(() =>
  import('components/new/super30/NewSuper30Page')
); // New Super30 Page

const NewSuper30LandingPage = React.lazy(() =>
  import('components/new/super30LandingPage/NewSuper30PageLandingPage')
); // New Super30 Page

const ContactUs = React.lazy(() =>
  import('components/new/contact-us/NewContactUsPage')
); // New Contact Us
const HirePage = React.lazy(() => import('components/old/hire'));
const StudentReviewsPage = React.lazy(() =>
  import('components/old/student-reviews/StudentReviewsPage')
);
const JoinAsMentor = React.lazy(() =>
  import('components/old/join-as-coach/JoinAsCoach')
);
const CareersPage = React.lazy(() =>
  import('components/old/careers/CareersPage')
);
const TermsCondition = React.lazy(() => import('./pages/TermsCondition'));
const RefundReturn = React.lazy(() => import('pages/RefundReturn'));
const PricingPage = React.lazy(() => import('pages/PricingPage'));
const GoldMembershipPage = React.lazy(() => import('pages/GoldMembership'));
const AboutUs = React.lazy(() => import('pages/AboutUs'));
const PrivacyPolicyPage = React.lazy(() =>
  import('components/old/common/PrivacyPolicyPage')
);
const JoinTheTeam = React.lazy(() =>
  import('components/old/join-the-team/JoinTheTeam')
);
const CounsellingSession = React.lazy(() =>
  import('./components/old/CouncellingSessions/CounsellingSession')
);
const NewHomePage = React.lazy(() => import('components/new/Home/NewHomePage')); // New Home Page
const TermsAndConditionsArchive = React.lazy(() =>
  import('TermsAndConditionsArchive/TermsAndConditionsArchive')
);
const Feb2022Version1 = React.lazy(() =>
  import('TermsAndConditionsArchive/Feb2022Version1')
);
const LifeAtProduct = React.lazy(() =>
  import('components/new/Life at Product/LifeAtProductPage')
); // Life at Product
const AboutUsPage = React.lazy(() =>
  import('components/new/AboutUs/AboutUsPage')
); // About Us Page
const Careers = React.lazy(() => import('components/new/Careers/CareersPage')); // Careers Page

// For SEO search
const siteMap = {
  'book-a-free-counselling-session': <CounsellingSession />,
  coaches: <MentorsPage />,
  pricing: <PricingPage />,
  'terms-conditions': <TermsCondition />,
  'privacy-policy': <PrivacyPolicyPage />,
  super30: <NewSuper30Page />,
  'contact-us': <ContactUs />,
  careers: <Careers />,
  'hire-from-us': <HirePage />,
  'life-at-product-companies': <LifeAtProduct />,
  'about-us': <AboutUsPage />,
  pc: <PersonalizedCoaching />,
  'student-reviews': <StudentReviewsPage />,
  'join-as-coach': <JoinAsMentor />,
  'join-the-team': <JoinTheTeam />,
};

export default function App() {
  const searchQuery = new URLSearchParams(window.location.search);
  // Google Analytics
  const TRACKING_ID = 'UA-166687594-1'; // YOUR_OWN_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);
  // ReactGA.pageview(window.location.pathname + window.location.search);

  // Google Tag Manager
  const tagManagerArgs = {
    gtmId: 'GTM-NWCGFHB',
  };
  TagManager.initialize(tagManagerArgs);

  const getRelevantPage = () => {
    let searchTerm = null;
    searchQuery.forEach((value, key) => {
      if (key === 'q') {
        searchTerm = value;
      }
    });

    if (!searchTerm) {
      return <NewHomePage />;
    }

    searchTerm = `${searchTerm}`.split(' ').join('-');
    if (searchTerm === 'super-30') {
      searchTerm = 'super30';
    }

    if (!siteMap[searchTerm]) {
      return <NewHomePage />;
    }
    return siteMap[searchTerm];
  };

  return (
    <Router>
      <Switch>
        <Route path='/gold-membership'>
          <Suspense fallback={<></>}>
            <GoldMembershipPage />
          </Suspense>
        </Route>

        <Route path='/search'>
          <Suspense fallback={<></>}>{getRelevantPage()}</Suspense>
        </Route>

        <Route path='/book-a-free-counselling-session'>
          <Suspense fallback={<></>}>
            <CounsellingSession />
          </Suspense>
        </Route>

        <Route path='/coaches'>
          <Suspense fallback={<></>}>
            <MentorsPage />
          </Suspense>
        </Route>

        <Route path='/pricing'>
          <Suspense fallback={<></>}>
            <PricingPage />
          </Suspense>
        </Route>

        {/* <Route path='/about'>
          <Suspense fallback={<></>}>
            <AboutUs />
          </Suspense>
        </Route> */}

        {/* <Route path="/refund-policy">
          <RefundReturn />
        </Route> */}

        <Route path='/terms-conditions'>
          <Suspense fallback={<></>}>
            <TermsCondition />
          </Suspense>
        </Route>

        <Route
          exact
          path='/tnc-archive'
        >
          <Suspense fallback={<></>}>
            <TermsAndConditionsArchive />
          </Suspense>
        </Route>

        <Route path='/tnc-archive/20-feb-2022'>
          <Suspense fallback={<></>}>
            <Feb2022Version1 />
          </Suspense>
        </Route>

        <Route path='/privacy-policy'>
          <Suspense fallback={<></>}>
            <PrivacyPolicyPage />
          </Suspense>
        </Route>

        <Route path='/super30'>
          <Suspense fallback={<></>}>
            <NewSuper30Page />
          </Suspense>
        </Route>

        <Route path='/super30-landing-page'>
          <Suspense fallback={<></>}>
            <NewSuper30LandingPage />
          </Suspense>
        </Route>

        <Route path='/contact-us'>
          <Suspense fallback={<></>}>
            <ContactUs />
          </Suspense>
        </Route>

        <Route path='/careers'>
          <Suspense fallback={<></>}>
            <Careers />
          </Suspense>
        </Route>

        <Route path='/hire-from-us'>
          <Suspense fallback={<></>}>
            <HirePage />
          </Suspense>
        </Route>

        <Route path='/life-at-product-companies'>
          <Suspense fallback={<></>}>
            <LifeAtProduct />
          </Suspense>
        </Route>

        <Route path='/about-us'>
          <Suspense fallback={<></>}>
            <AboutUsPage />
          </Suspense>
        </Route>

        <Route path='/'>
          <Suspense fallback={<></>}>
            <NewHomePage />
          </Suspense>
        </Route>

        <Route path='/pc'>
          <Suspense fallback={<></>}>
            <PersonalizedCoaching />
          </Suspense>
        </Route>

        <Route path='/student-reviews'>
          <Suspense fallback={<></>}>
            <StudentReviewsPage />
          </Suspense>
        </Route>

        <Route path='/join-as-coach'>
          <Suspense fallback={<></>}>
            <JoinAsMentor />
          </Suspense>
        </Route>

        <Route path='/join-the-team'>
          <Suspense fallback={<></>}>
            <JoinTheTeam />
          </Suspense>
        </Route>

        <Redirect to='/' />
      </Switch>
    </Router>
  );
}
